@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
  box-sizing: border-box;
  
}

html {
  margin: 0; padding: 0;
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.02rem;
  font-size: 16px;
  color: #ffffffee;
}

body {
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  background: rgb(24,37,50);
}

.main {
  min-height: 100vh;
  position: relative;
  background: rgb(24,37,50);
}

.contentWrap {
  padding-bottom: 17rem;
}

.footer {
  height: 15rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem 0 1rem 0;
  background: #00000055;
}

.footerRF {
  font-size: 1rem;
  font-weight: 400;
  text-shadow: 0 -1px 1px #00000077;  
  color: #83c7ff !important;
}

.footerSmallPrint {
  padding: 0.1rem 0 0.1rem 0;
  margin: 0;
  font-size: 0.8rem;
  color: #ffffff77;
}


h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 900;
  color: #ffffffee;
}

h1 {
  font-size: 3rem;
  font-weight: 300;
  color: #ffffffff; 
}

h1 strong {
  font-weight: 900;
}

.pageHeading {
  font-size: 2rem;
}

a, a:visited {
  text-decoration: none !important; 
  color: #ffffffee !important;
}

a:visited {
  color: #353539;
}

p, li {
  font-size: 0.9rem;
}

p.productPageText, ul.productPageList li {
  color: white;
  font-size: 1rem;
  line-height: 1.5rem;;
  font-weight: 500;
}

ul.productPageListSmall {
  padding-inline-start: 1rem;
  list-style-type: disc;
  margin-block-start: 0;
  margin-block-end: 0;
}

ul.productPageList {
  padding-inline-start: 1rem;
  list-style-type: disc;
}

.productPageSubHeading {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  text-transform: uppercase;
  color:rgb(135, 209, 255)
}

.supportSubHeading {
  color: #ffffff;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  font-size: 1.4rem;;
}

.advert {
  color: #ffffffee;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.advert sup {
  font-size:0.5rem;
}

.menuItem {
  font-size: 0.9rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.5rem 0.7rem 0.5rem 0.7rem;
  border-radius: 0.2rem;
  transition: all 0.3s;
}

.menuItem:hover {
  background: #75caff2e;
  cursor: pointer;
}

.active {
  background-color: #75caff2e;
  color: #ffffff;
}

.active:hover {
  background: #75caff4e;
}

.homeTile {
  padding: 2rem 1.5rem;
  border-radius: 0.9rem;
  color: #ffffffcc;
  background: rgb(36,57,94);
  background: linear-gradient(311deg, rgb(26, 51, 96) 0%, rgb(44, 108, 156) 100%);
  border: 1px solid #3e3e5c43;
  transition: all 0.4s;
  height: 100%;
}

.homeTile:hover {
  background: rgb(46,67,110);
  background: linear-gradient(311deg, rgba(46,67,110,1) 0%, rgba(78,120,179,1) 100%);
  box-shadow: rgba(12, 11, 19, 0.781) 0px 2px 12px 0px;
}

.homeTileHeading {
  color: white;
  text-shadow: 0 -1px 1px #00000077;
  font-size: 2.5rem;
  font-weight: 900;
}

.homeTileDescription {
  font-size: 1rem;
  padding: 0 0 1rem 0;
  font-weight: 500;
}

.homeTileDescription, .homeTileHeading {
  text-align: center;
}

.homeTileProductButton {
    font-size: 0.9rem;
    text-transform: uppercase;
    color: #000000ff !important;
    background-color: #ffffffcc;
    letter-spacing: -0.02rem;
    padding: 0.5rem 1rem;
    background: linear-gradient(0deg, #c2d4d5 0%, #ffffff 100%);
    font-weight: 700;
    border-radius: 0.3rem;
    display: inline-flex !important;
  }

.downloadButton {
  font-size: 0.8rem !important;
  font-weight: 500;
  text-transform: none;
  color: #ffffffcc !important;
  background-color: #ffffff11;
  letter-spacing: -0.02rem;
  border: 1px solid #ffffff77;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  display: inline-flex !important;
}

.homeTileProductButton:visited, .downloadButton:visited {
  color: #000000ff !important;
}

.homeTileProductButton:hover {
  background: linear-gradient(0deg, #c2d4d5 0%, #ffffff 100%);
  box-shadow: rgba(12, 11, 19, 0.781) 0px 2px 12px 0px;
  cursor: pointer;
}

.downloadButton:hover {
  background-color: #ffffff33;
}


.linkButton {
  font-size: 0.9rem;
    text-transform: uppercase;
    color: #ffffffcc !important;
    letter-spacing: -0.02rem;
    padding: 0.5rem 1rem;
    border: 1px solid #ffffff77;
    font-weight: 700;
    border-radius: 0.3rem;
    display: inline-flex !important;
}

.productDetailTile {
  padding: 3rem;
  margin-bottom: 1rem;
  border-radius: 0.9rem;
  color: #ffffffcc;
  background: rgb(36,57,94);
  background: linear-gradient(311deg, rgb(26, 51, 96) 0%, rgb(44, 108, 156) 100%);
  border: 1px solid #3e3e5c43;
}

.productContainer {
  padding: 1rem 3rem 1rem 1rem;
}

.productDetailHeading {
  color: #ffffffcc;;
  text-shadow: 0 -1px 1px #00000077;
  font-size: 2.5rem;
  font-weight: 300;
}

.productDetailHeading strong {
  font-weight: 900;
  color: white; 
}

.tileHeading {
  font-size: 1.8rem;
  font-weight: 900;
  padding-bottom: 1rem;
  text-align: left;
}

.cockpitGaugeTile {
  text-align: center;;
  padding: 1rem;
  background: #00000044;
  border-radius: 1rem;
  height: 100%;
  font-size: 1.1rem;
  transition: all 0.2s;
}

.cockpitGaugeTile:hover {
  background: #00000077;
}

.reviewTile, .compatibilityTile, .docsTile {
  padding: 1rem;
  border-radius: 0.4rem;
  color: #ffffffcc;
  background: rgba(47, 73, 119, 0.2);
  border: 1px solid #48487164;
  font-size: 0.9rem;
  width: 100%;
}

.reviewTile p {
  font-size: 0.9rem;
}

.reviewTile h5 {
  font-weight: 700;
  font-size: 1rem;
}

.logoSmall {
  margin-left: 2rem;  
  font-size: 1.2rem;
  font-weight: 300;
}

.logoSmall strong {
  font-weight: 900;

}

.menuBar {
  backdrop-filter: blur(6px);
  margin-left: -1rem;
  margin-right: -1rem;
  
  -webkit-backdrop-filter: blur(6px);
  padding: 0.5rem 0 0.5rem 0;
  padding-left: 1rem;
  padding-right: 1rem;
  background: rgba(24,37,50, 0.7);
  border-radius: 0 0 0.5rem 0.5rem;
}

.productBarSticky {
  backdrop-filter: blur(6px);
  margin-left: -1rem;
  margin-right: -1rem;
  
  -webkit-backdrop-filter: blur(6px);
  padding: 1rem 3rem 1rem 3rem;
  background: rgba(24,37,50, 0.7);
  border-radius: 0 0 0.5rem 0.5rem;
}


.docsList {
  padding-inline-start: 1rem;
  list-style-type: disc;
  line-height: 1.5rem;
  color: #ffffffee;
}

ol.docsList {
  list-style-type: decimal;
}

ol.docsList li {
  padding: 0 0 0 0.2rem;

}

.docsList li strong, pre {

  font-weight: 700;
  outline: 1px solid #ffffff22;
  color: white;
  background: #00000033;;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  border-radius: 0.1rem;
}

pre {
  display: inline;
  font-size: 0.8rem;
  font-weight: 400;
  font-family: monospace !important;
}

.ticket-form-container {
  z-index: 100;
}


.menuBarShadow {
  xbox-shadow: 0 5px 25px 0 #00000066;
}

@media screen and (max-width: 680px) {
  html {
    font-size: 15px;
  }

  .advert, .logo  {
    text-align: center;
  }

  .productDetailTile {
    padding: 2rem 1.5rem 1.5rem 1.5rem;
  }

  .logoSmall {
    margin-left: 0;
    font-size: 1.5rem;
    margin-bottom: -1rem;
    padding-bottom: 0;
    
  }

  h2 {
    text-align: center;
  }

  .pageHeading {
    text-align: center;
    font-size: 1.8rem;
  }
}